.StatCard{
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #1e1e1e;
  color: white;
  width: 100px;
  text-align: center;

  &__title{
    color: white;
  }

  &__unit{
    margin-left: 1px;
    color: #888888;
  }

  &__value{
    color: #bb86fc;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: -1px;
  }

}
